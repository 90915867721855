.upper-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

/* .upper-container-compact {
  display: flex;
  align-items: center;

  justify-content: space-between;
  width: 100%;
} */

.room-number-box {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 115px;
  height: 115px;
  background: #F3F3F3;
  border-radius: 100%;
  text-align: center;
  font-size: 38px;
  margin-left: 40px;
  margin-top: -4px;
  position: absolute;
}

/* .room-number-box-compact {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 30px;
  background: #F3F3F3;
  border-radius: 20px;
  text-align: center;
  font-size: 25px;
} */

.room-number-box-minimal {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 30px;
  background: #F3F3F3;
  border-radius: 20px;
  text-align: center;
  font-size: 25px;
  margin:2px
}

.access-control-name-box-minimal {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 30px;
  background: #f5f5f5;
  border-radius: 20px;
  text-align: center;
  font-size: 13px;
  margin:2px
}

.status-container-medium {
  flex-grow: 1;
  height: 30px;
  border-radius: 5px;
  background: white;
  border: solid lightgray 1px;
  margin-left: 7px;
  flex-flow: row nowrap !important;
  background-color: #f7f8fc;
}

/* .status-container-medium-compact {
  flex-grow: 1;
  height: 26px;
  width: 30px;
  border-radius: 20px;
  background-color: #f7f8fc;
} */

.status-container-minimal {
  flex-grow: 1;
  height: 30px;
  border-radius: 5px;
  background: white;
  border: solid lightgray 1px;
  margin-left: 12px;
  margin-right: 5px;
  flex-flow: row nowrap !important;
  background-color: #f7f8fc;

}

.status-container-small {
  width: 120px;
  height: 30px;
  border-radius: 5px;
  border: solid lightgray 1px;
  flex-grow: 1;
  margin-left: 7px;
  flex-flow: row nowrap !important;
  background-color: #f7f8fc;
}

.primary-status-container {
  flex-grow: 1;
  margin-left: 199px;
}

.margin-top12 {
  margin-top: 12px;
}

.icon-background-on {
  background-color: #7472D1;
  color: white;
  width: 30px;
  height: 30px;;
  border-radius: 100px;
  align-self: center;
  margin-left: -10px;
  margin-top: -1px;
}

.icon-background-off {
  background-color: #D2D2D2;
  width: 30px;
  height: 30px;
  border-radius: 100px;
  align-self: center;
  margin-left: -10px;
  margin-top: -1px;
}

.icon-background-alarm {
  background-color: #ff0000;
  width: 30px;
  height: 30px;
  border-radius: 100px;
  align-self: center;
  margin-left: -10px;
  margin-top: -1px;
}

/* .icon-background-on-compact {
  background-color: #7472D1;
  width: 21px;
  height: 21px;
  border-radius: 100px;
  align-self: center;
  margin-left: 3px;
  font-size: 15px !important;
} */

/* .icon-background-off-compact {
  background-color: #D2D2D2;
  width: 21px;
  height: 21px;
  border-radius: 100px;
  align-self: center;
  margin-left: 3px;
  font-size: 15px !important;
} */

/* .icon-background-alarm-compact {
  background-color: #ff0000;
  width: 21px;
  height: 21px;
  border-radius: 100px;
  align-self: center;
  margin-left: 3px;
  font-size: 15px !important;
} */

.status-text-alarm {
  align-self: center;
  margin-left: 5px;
  color: #ff0000;
  font-size: 14px;
  font-weight: 700;
}

.status-text-on {
  align-self: center;
  margin-left: 5px;
  color: #7472D1;
  font-size: 14px;
  font-weight: 700;
}

.status-text-off {
  align-self: center;
  margin-left: 5px;
  color: #808080;
  font-size: 14px;
  font-weight: 400;
}

/* .status-text-on-compact {
  align-self: center;
  margin-left: 3px;
  color: #7472D1;
  font-size: 13px;
  // font-weight: 700;
} */

/* .status-text-off-compact {
  align-self: center;
  margin-left: 3px;
  color: #808080;
  font-size: 13px;
  font-weight: 400;
} */

.overview-alarm-count {
  position: absolute;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  right: 10px;
  top: 12px;
  color: red;

}

.alarm-indicator-active {
  background-color: #ff0000;
  width: 12px;
  height: 12px;
  border-radius: 100px;
  margin-left: 5px;
  margin-top:2px
}

.alarm-indicator-inactive {
  background-color: #808080;
  width: 12px;
  height: 12px;
  border-radius: 100px;
  margin-left: 5px;
  margin-top:2px
}

.location-text {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  letter-spacing: 0.3px;
  color: #4B506D;
  }
