$selected-item-border: true;
$selected-item-border-color: #0462F8;
$selected-item-border-size: 2px;
$box-shadow: false;
$range-start-border: false;

@import "ngx-drag-to-select";
@import "@danielmoncada/angular-datetime-picker/assets/style/picker.min.css";

@import 'assets/css/icons.scss';
@import 'assets/css/variables.scss';
@import 'assets/css/content.scss';
@import 'assets/css/logs.scss';
@import 'assets/css/tooltip.scss';
@import 'assets/css/checkbox.scss';
@import 'assets/css/loading-indicator.scss';
@import 'assets/css/toggle.scss';
@import 'assets/css/grouping.scss';
@import 'assets/css/permissions.scss';
@import 'assets/css/buttons.scss';
@import 'assets/css/overview-card.scss';
@import 'assets/css/icon-grid.scss';
@import 'assets/icon/icon';

// @font-face {
//   font-family: 'SourceSansPro';
//   src: url('/assets/font/SourceSansPro-Regular.ttf') format("truetype"), url('/assets/font/SourceSansPro-Bold.ttf'), url('/assets/font/SourceSansPro-Light.ttf'), url('/assets/font/SourceSansPro-Italic.ttf');
// }

@font-face {
  font-family: 'Mulish';
  src: url('/assets/font/Mulish-Regular.ttf') format("truetype"), url('/assets/font/Mulish-Bold.ttf'), url('/assets/font/Mulish-Light.ttf'), url('/assets/font/Mulish-Italic.ttf');
}

html {
    box-sizing: border-box;
    // overflow-y: scroll;
    overflow-x: hidden;
}

h1 {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

h2 {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background-color: #f8f8f8;
  border-radius: 0px;
}

::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: $primary;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

*, *::before, *::after {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Mulish', sans-serif;
  background-color: white;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0.2px;
}

p {
  font-family: 'Mulish', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0.2px;
  margin: 2px 5px;
}

div {
  font-family: 'Mulish', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0.2px;
}

hr {
  border: 0;
  clear:both;
  display:block;
  width: 100%;
  background-color: $dark;
  height: 1px;
}

a {
  text-decoration: none;
}


app-footer {
  margin-top: auto;
  height: 30px;
  background-color: white;
}

app-users,app-automation, app-new-automation, app-user-details, app-audit,app-access-logs, app-live-log, app-alarms-subscriptions, app-unconfirmed-alarms, app-alarm-logs, app-card-events-log, app-roles, app-role-details, app-locations, app-settings, app-user-permissions, app-user-card-edit, app-cards, app-edit-views, app-card-validity, app-alert-subscriptions, app-edit-controllers-overview, app-project-details, app-other-settings, app-groups, app-hvac-mode, app-edit-defaults, app-room-overview, app-corridors-overview, app-schedule, app-hvac-mode-cross-reference, app-controller-settings, app-analytics /* , app-room-overview */ {
  display:flex;
  flex-direction: column;
  background-color: $background;
  // background-color: $light;
  padding: 0 30px;
  padding-top: 20px;
  min-height: calc(100% - 10px);
  @media screen and (max-width: $largeScreen) {
    padding: 0 30px;
    }
  @media screen and (max-width: $smallScreen) {
    margin-left:0px;
    width: 100vw;
    }
 /*  display:flex;
  flex-direction: column;
  background-color: $light;
  padding: 0 100px;
  padding-top: 20px;
  width: calc(100vw - 200px);
  @media screen and (max-width: 1100px) {
    width: calc(100vw - 80px);
  }
  @media screen and (max-width: 800px) {
    padding: 0 40px;
    padding-top: 20px;
  }
  @media screen and (max-width: 600px) {
    width: 100vw;
  } */

}

/* app-corridors-overview {
  display:flex;
  flex-direction: column;
  background-color: $background;
  @media screen and (max-width: $largeScreen) {
    padding: 0 30px;
    }
  @media screen and (max-width: $smallScreen) {
    margin-left:0px;
    width: 100vw;
    }
}

app-room-overview {
  display:flex;
  flex-direction: column;
  background-color: $background;
} */

/* app-home {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 10;
  background-color: $light;
} */


  /* ** FLEXBOX ** */
.flex-display {
  display: flex;
}

.flex-row {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: flex-start;
}

.flex-row-space-around {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  align-items: flex-start;
}

.flex-row-space-between {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-start;
}
.flex-row-space-evenly {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  align-items: flex-start;
}

.flex-row-center-h {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  align-items: flex-start;
}

.flex-row-space-around-center {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  align-items: center;
}


.flex-row-center-v {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
}


.flex-row-center-full {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
}

.flex-row-center-vertical {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
}

.flex-row-start-end {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: flex-end;
}

.flex-row-end-end {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-end;
  align-items: flex-end;
}


.flex-column {
  display: flex;
  flex-flow: column wrap;
  justify-content: flex-start;
  align-items: flex-start;
}

.flex-column-center-full {
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-align-center {
  align-items: center
}

.flex-column-space-between {
  display: flex;
  flex-flow: column wrap;
  justify-content: space-between;
  align-items: flex-start;
}

section {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.no-wrap {
  flex-wrap: unset !important;
}

.from-back {
  justify-content: flex-end;
}


  /* ** MARGIN, PADDING, WIDTH, ... , ** */

  @mixin attr-x($attr, $attr-count: 10, $attr-steps: 10, $unit: '%') {
    $attr-list: null;
    @for $i from 1 through $attr-count {
        $attr-value: $attr-steps * $i;

        .#{$attr}#{$attr-value} {
            #{$attr}: #{$attr-value}#{$unit};
        }

        $attr-list: append($attr-list, unquote(".#{$attr}-#{$attr-value}"), comma);
    }
}

.margin-right-100 {
  margin-right: 100px!important;
}

// (property, number of iterations, iteration step X value, measurement unit)
// bottom line create classes .margin-left5 , .margin-left10, ... , .margin-left30
@include attr-x('margin', 20, 5, 'px');
@include attr-x('margin-top', 40, 5, 'px');
@include attr-x('margin-right', 40, 5, 'px');
@include attr-x('margin-bottom', 40, 5, 'px');
@include attr-x('margin-left', 40, 5, 'px');

@include attr-x('margin-top', 10, 1, 'px');
@include attr-x('margin-right', 10, 1, 'px');
@include attr-x('margin-bottom', 10, 1, 'px');
@include attr-x('margin-left', 10, 1, 'px');

@include attr-x('padding-left', 2, 5, 'px');
@include attr-x('padding', 20, 5, 'px');
@include attr-x('padding-right', 20, 5, 'px');
@include attr-x('width',100,1,'%');
@include attr-x('font-size',50,1,'px');

.height100 {
  height: 100%;
}

.margin-auto {
  margin: auto;
}

.margin-top0 {
  margin-top: 0px;
}

.margin-bottom0 {
  margin-bottom: 0px;
}

.margin-left0 {
  margin-left: 0px;
}

.margin-right0 {
  margin-right: 0px;
}
.margin-left335 {
  margin-left: 335px;
}

.to-right {
  margin-left: auto ;
}

.to-left {
  margin-right: auto ;
}

.width-auto {
  width: auto !important;
}

.width-full {
  width: 100%;
}

.white {
  color: white;
}

.black {
  color: black;
}

.red {
  color: red;
}

.orange {
  color: orange;
}

.blue {
  color:blue;
}

.gray {
  color: gray;
}

.light-gray {
  color: lightgray;
}

.green {
  color: green;
}

.yellow {
  color: yellow;
}

.header-arrow {
  margin-left: 10px;
  border: solid $primary;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
}


  /* ** BUTTON ** */


button {
  border: none;
  background-color: $primary;
  color: $light;
  padding: 8px 22px;
  margin: 10px 5px;
  cursor: pointer;
  transition: all 0.5s;
}

button:hover {
  background-color: $secondary;
  color: $primary;
}

  /* ** TEXT ** */

  .text-align-center {
    text-align: center !important;
  }

  .text-align-right {
    text-align: right
  }

  .contents {
    display: contents;
  }


  /* ** BORDER ** */


  .br {
    border: 2px solid $secondary;
  }

  .br-gray {
    border: 0.5px solid LightGray;
  }

  .br-gray-r   {
    border-right: 0.5px solid LightGray;
  }

  .br-gray-l {
    border-left: 0.5px solid LightGray;
  }

  .br-gray-t {
    border-top: 0.5px solid LightGray;
  }

  .br-gray-b {
    border-bottom: 0.5px solid LightGray;
  }

  .br-r {
    border-right: 2px solid $secondary;
  }

  .br-r-s {
    border-right: 1px solid $secondary-dark;
  }

  .br-l {
    border-left: 2px solid $secondary;
  }

  .br-l-s {
    border-left: 1px solid $secondary-dark;
  }

  .br-b {
    border-bottom: 2px solid $secondary;
  }

  .br-b-s {
    border-bottom: 1px solid $secondary-dark;
  }

  .br-rounded {
    border-radius: 15px;
  }

  .ion-no-border {
    --border-style: none;
  }

  //IONIC

  ion-radio::part(container) {
    border-width: 6px !important;
  }

  ion-radio::part(mark) {
    width: 0px !important;
  }
  ion-toggle.toggle-checked::part(track) {
    background: #2dd36f;
  }

  ion-toggle::part(handle) {
    background: white;
    height: 12px;
    width: 12px;
    left: 5px;
  }

  /* ** TAB ** */
  .tab {
    width: 50%;
    text-align: center;
    margin: 0px;
    padding: 5px 15px;
    font-size: 1.1rem;
    cursor: pointer;
  }

  .tab:hover {
    background-color: $secondary;
  }

  .tab-selected {
    color: $light;
    background-color: $primary;
  }

  .z-index9999 {
    z-index: 9999;
  }
  /* ** MISC ** */

  .item-background-color {
    --min-height: 0px;
    --ion-item-background:#EFEDED;
    --padding-start: 9px;
  }

  .item-background-color-white {
    --min-height: 0px;
    --ion-item-background:white;
    --padding-start: 9px;
  }


  .ocean-blue {
    color: $ocean-blue;
  }

  .middle {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    font-size: clamp(0.8rem, 0.728em + 2.86vw, 1.6rem);
  }

  .no-transform {
    &:hover {
        transform: none !important;
        box-shadow: none !important;
      }
}

.clickable {
  cursor: pointer;
}

.not-clickable {
  cursor: default;
}

.no-top {
  top: 0px ;
}

.overflow-auto {
  overflow: auto;
}

.rotate {
  transform: rotateZ(180deg);
  transform-origin:center center;
  transition-duration: .5s;
}

.rotate-back {
  transform: rotateZ(0deg);
  transform-origin:center center;
  transition-duration: .5s;
}

.outline-none {
  outline: none;
}

.vertical-text {
  transform: rotate(-90deg);
  white-space: nowrap;
}

.room-modal {
  --width: 90vw;
  --height:90vh;
}

.locations-drag-modal {
  --width: 90vw;
  --height:90vh;
}

.hvac-mode-modal {
  --width: 70vw;
  --height: 70vh;
}

.enter-room-number-modal {
  // --width: 20vw;
  --width:400px;
  // --height: 15vh;
  --height: 200px;
  --border-radius: 4px;
}
.card-login {
  --width: 20vw;
  --height: 15vh;
  --border-radius: 4px;
}

.cards-popover {
  --width: 95vw;
  --height: 95vh;
}

.date-time-inline-popover {
  --width: 50vw;
}

.button-select {
  --width: auto;
}


.filter-popover {
  --width: 280px;
}

.filter-popover-two-rows {
  --width: 450px;
}

.ion-btn-pad-sm {
  --padding-bottom:0px;
  --padding-end:4px;
  --padding-start:4px;
  --padding-top:0px;
  background-color: #D9D9D9;
  --border-width: 0px !important;
  border-radius: 5px !important;
}

.stack-modal {
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.32);
}

.temp-box-content {
  text-align: center;
  margin-left: 15px;
  margin-right: 15px;
}

.black {
  color:black
}

.sc-ion-modal-md-h {
  --border-radius: 10px;
}

.cards-popover .modal-wrapper.sc-ion-modal-md {
  width: 90% !important;
  height: 90% !important;
}

.time-select {
  --width: 40vw;
  --height: 30vh;
  background: rgba(26, 25, 25, 0.45)
}

.no-click {
  pointer-events: none !important;
  cursor: unset !important;
}

.btn-disabled {
  background-color: $disabled-button !important;
}

.ion-list-activated {
  --ion-item-background: #e5ff9a
}

.center {
  text-align: center;
}


  /* ** FONT ** */

  // (property, number of iterations, iteration step X value, measurement unit)
// bottom line create classes .margin-left5 , .margin-left10, ... , .margin-left30
@include attr-x('font-size', 100, 1, 'px');


// Room card status

.sos-guest-in-room {
  background-color: $sosGuestInRoom;
}

.sos-card-not-deposited {
  background-color: $sosCardNotDeposited;
}

.room-not-rented {
  background-color: $roomNotRented;
}

.room-rented {
  background-color: $roomRented;
}

.guest-card-deposited {
  background-color: $guestCardDeposited;
}

.staff-card-deposited {
  background-color: $staffCardDeposited;
}

.unauthorized-access {
  background-color: $unauthorizedAccess;
}

.bg-white {
  background-color: white;
}

  /* ** SEARCH FIELD ** */

.search-wrapper{
  position: relative;
  display: flex;
  flex-flow: row wrap;
  height: 35px;
}

.search-input{
  box-sizing: border-box;
  background: #FCFDFE;
  border: 1px solid #F0F1F7;
  border-radius: 8px;
  padding: 10px 50px 10px 10px;
  color: rgba(0, 0, 0, 0.4);
}

.icon-search {
  position:absolute;
  right:2px;
  top:8px;
}




.view-text {
text-transform: uppercase;
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 20px;

letter-spacing: 0.3px;
color: #4B506D;
}

.big-radio {
  transform: scale(1.5);
}

.uppercase {
  text-transform: uppercase;
}


.filer {
font-style: normal;
font-weight: 700;
font-size: 14px;
line-height: 20px;
letter-spacing: 0.3px;
color: #1A1A1A;
}


.up {
  transform: rotateZ(-135deg);
  transform-origin:center center;
  transition-duration: .5s;
}

.down {
  transform: rotateZ(45deg);
  transform-origin:center center;
  transition-duration: .5s;
}

.vertical-line {
  border:1px solid #DFE0EB;
  height: 26px;
  margin: 7px 10px 0px 23px;
}


  /* ** OVERLAY ** */

  .grayscale {
    filter: grayscale(100%);
  }



.opacity0-4 {
  opacity: 0.4;
}

  /* ** TABLE ** */


table {
  width: 100%;
}

td {
  color: black;
  text-align: center;
  padding: 8px;
}

tr {
  border-bottom: 0.5px solid LightGray;
}

th {
  background-color: #fff;
  color: black;
  text-align: center;
}


.icon-left {
  min-width: 40px;
  text-align: start !important;
}

.icon-left-text {
  min-width: 40px;
  text-align: start !important;
  font-family: Mulish,sans-serif !important;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: .2px;
}

.bold-font {
  font-weight: bold;
}

// SCHEDULE SCSS
.affected-area-container {
  width: 100px;
  height: 100px;
  font-weight: 700;
  border-radius: 10px;
  margin: 20px;
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.07) 0px 2px 2px, rgba(0, 0, 0, 0.07) 0px 4px 4px, rgba(0, 0, 0, 0.07) 0px 8px 8px, rgba(0, 0, 0, 0.07) 0px 16px 16px;
}

.controller-lights-container{
  width: 95%;
  min-height: 100px;
  border-radius: 10px;
  margin: 20px;
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.07) 0px 2px 2px, rgba(0, 0, 0, 0.07) 0px 4px 4px, rgba(0, 0, 0, 0.07) 0px 8px 8px, rgba(0, 0, 0, 0.07) 0px 16px 16px;
}

.light-outer {
  width: 80px;
  height: 80px;
  margin: 5px;
  border-radius: 50%;
  background-color: #F3F3F3;
}

.light-outer-on {
  width: 80px;
  height: 80px;
  margin: 5px;
  border-radius: 50%;
  background-color: #FDCD23;
}

.light-outer-off {
  width: 80px;
  height: 80px;
  margin: 5px;
  border-radius: 50%;
  background-color: #6A6969;
}

.light-outer-blue {
  width: 80px;
  height: 80px;
  margin: 5px;
  border-radius: 50%;
  background-color: blue;
}

.light-inner {
  width: 60%;
  height: 40%;
  background-color:white
}

.blue-circle {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: blue;
  position: absolute;
  top: 20px;
  right: 20px;
}

.relative {
  position: relative
}

.room-card {
  width: 400px;
  height: 220px;
  // margin: 12px;
  // box-shadow: 0px 4px 4px 0px #00000040;
  position: relative;
  border-radius: 14px;
  border: solid 1px lightgray;
  background-color: white;
}

/* ACCESS CONTROL/COMMON AREAS CARD */

.access-control-top-bar {
  height: 10px;
  background-color: $access-control-main-color;
  border-radius: 10px 10px 0px 0px

}

.access-control-top-bar-minimal {
  height: 10px;
  background-color: $access-control-main-color;
}


/* .access-control-name-compact {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  border: 1px black solid;
  background-color: whitesmoke;
  font-size: large;
  padding: 0px 10px 0px 10px;
  border-radius: 5px;
  min-width: 100px;
} */

/* .access-control-card {
  width: 300px;
  height: 122px;
  box-shadow: 0px 4px 4px 0px #00000040;
  position: relative;
  background-color: white;
} */

.access-control-card {
  width: 300px;
  height: 122px;
  border-radius: 14px;
  position: relative;
  background-color: white;
  border: solid 1px lightgray;
}



/* .access-control-card-compact {
  width: 110px;
  height: 80px;
  box-shadow: 0px 4px 4px 0px #00000040;
  position: relative;
  background-color: white;
  border-radius: 14px;
} */

.access-control-card-minimal {
  width: 80px;
  height: 50px;
  box-shadow: 0px 4px 4px 0px #00000040;
  position: relative;
  background-color: white;
}

.access-control-card-content {
  margin: 20px;
}

/* .access-control-card-content-compact {
  margin: 4px;
} */

.status-container-full {
  flex-grow: 1;
  height: 30px;
  border-radius: 20px;
  background-color: #f7f8fc;
}

.icon-background-black {
  background-color: $access-control-main-color;
  width: 30px;
  height: 30px;
  border-radius: 100px;
  align-self: center;
}

.access-control-name {
  align-self: center;
  margin-left: 5px;
  color: $access-control-main-color;
  font-size: 14px;
  font-weight: 700;
}

.access-control-inactive-overlay {
  position: absolute;
  width: 298px; // 2px smaller then access card for border
  height: 120px; // 2px smaller then access card for border
  background-color: grey;
  opacity: 0.5;
  filter: grayscale(100%);
  z-index: 2;
  border-radius: 10px;
}

/* .access-control-inactive-overlay-compact {
  position: absolute;
  width: 110px;
  height: 80px;
  background-color: grey;
  opacity: 0.5;
  filter: grayscale(100%);
  z-index: 2;
  border-radius: 14px;
} */

.access-control-inactive-overlay-minimal {
  position: absolute;
  width: 80px;
  height: 50px;
  background-color: grey;
  opacity: 0.5;
  filter: grayscale(100%);
  z-index: 2;
}

.width200 {
  width: 200px;
}

.margin-bottom12{
  margin-bottom:12px
}

.float-right {
  float: right
}

.float-left {
  float: left;
}
